import React, { useState, createContext, useEffect } from 'react';
import { ITransaction } from 'src/types/transaction';

interface AppContextInterface {
  balance: string;
  transactions: ITransaction[];
  updateContext: (obj: Partial<IAppContextState>) => void;
  children?: React.ReactNode;
}

interface IAppContextState {
  balance: string;
  transactions: ITransaction[];
}

export const initialState: IAppContextState = {
  balance: '',
  transactions: [],
};

export const AppContext = createContext<AppContextInterface | null>(null);

export default function ProviderComponent({
  children,
}: {
  children: React.ReactNode;
}) {
  const [context, setContext] = useState(initialState);
  useEffect(() => {}, [context]);

  const updateContext = (contextUpdates = {}) =>
    setContext((currentContext) => ({ ...currentContext, ...contextUpdates }));

  return (
    <AppContext.Provider value={{ ...context, updateContext }}>
      {children}
    </AppContext.Provider>
  );
}
