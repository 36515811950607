import type { FC } from "react";
import { useRoutes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import RTL from "src/components/RTL";
import SplashScreen from "src/components/SplashScreen";
import useAuth from "src/hooks/useAuth";
import useScrollReset from "src/hooks/useScrollReset";
import useSettings from "src/hooks/useSettings";
import routes from "src/routes";
import { createCustomTheme } from "src/theme";
import ProviderComponent from "src/contexts/AppContext";

const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();

  useScrollReset();

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  return (
    <ThemeProvider theme={theme}>
      <ProviderComponent>
        <RTL direction={settings.direction}>
          <CssBaseline />
          <Toaster position="top-center" />
          {auth.isInitialized ? content : <SplashScreen />}
        </RTL>
      </ProviderComponent>
    </ThemeProvider>
  );
};

export default App;
