import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Card,
  CardContent,
  Container,
  Typography,
} from '@material-ui/core';
import { LoginJWT } from 'src/components/authentication/login';
import Logo from 'src/components/Logo';

const platformIcons = {
  JWT: '/static/icons/jwt.svg',
};

const Login: FC = () => (
  <>
    <Helmet>
      <title>Login | Jobcoin</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <Container maxWidth="sm" sx={{ py: '80px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mb: 8,
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40,
              }}
            />
          </RouterLink>
        </Box>
        <Card>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: 4,
            }}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                mb: 3,
              }}
            >
              <div>
                <Typography color="textPrimary" gutterBottom variant="h4">
                  Welcome!
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  Sign In With Your Jobcoin Address
                </Typography>
              </div>
              <Box
                sx={{
                  height: 32,
                  '& > img': {
                    maxHeight: '100%',
                    width: 'auto',
                  },
                }}
              >
                <img alt="Auth platform" src={platformIcons.JWT} />
              </Box>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                mt: 3,
              }}
            >
              <LoginJWT />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
  </>
);

export default Login;
