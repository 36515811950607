import axios from 'axios';
import { ITransaction } from 'src/types/transaction';

interface AddressT {
  balance: string;
  transactions: ITransaction[];
}

const URL = {
  sendByAddress: 'https://jobcoin.gemini.com/mousiness-copper/api/transactions',
  getAddress: 'https://jobcoin.gemini.com/mousiness-copper/api/addresses/',
};

export const sendByAddress = async (
  fromAddress: string,
  toAddress: string,
  amount: number,
): Promise<any> =>
  axios
    .post(URL.sendByAddress, {
      fromAddress,
      toAddress,
      amount,
    })
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error.response.data.error);
    });

export const getDataByAddress = async (
  address: string,
): Promise<AddressT | void> =>
  axios
    .get(`${URL.getAddress}${address}`)
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      console.error(err);
    });

export const updateBalance = async (address: string, cb: Function) => {
  const response = await getDataByAddress(address);
  const dataToUpdate = {
    ...response,
  };
  cb(dataToUpdate);
};
