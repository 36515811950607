// @ts-nocheck

import { sign, decode, JWT_SECRET, JWT_EXPIRES_IN } from 'src/utils/jwt';
import wait from 'src/utils/wait';
import { User } from 'src/types/user';
import { getDataByAddress } from 'src/utils/fetch';

class AuthApi {
  async login({ address }): Promise<string> {
    await wait(500);

    const response = await getDataByAddress(address);
    const { balance, transactions } = response;

    return new Promise((resolve, reject) => {
      try {
        if (balance === '0' || transactions.length === 0) {
          reject(
            new Error(
              `Address "${address}" doesn't exist. Please enter an existing address`,
            ),
          );
        }

        const accessToken = sign(address, JWT_SECRET, {
          expiresIn: JWT_EXPIRES_IN,
        });

        resolve(accessToken);
      } catch (err) {
        console.error('[Auth Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  me(accessToken): Promise<User> {
    return new Promise((resolve, reject) => {
      try {
        const user = decode(accessToken);

        if (!user) {
          reject(new Error('Invalid authorization token'));
          return;
        }

        resolve(user);
      } catch (err) {
        console.error('[Auth Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }
}

export const authApi = new AuthApi();
