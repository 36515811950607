import type { FC } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, FormHelperText, TextField } from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import useMounted from 'src/hooks/useMounted';

const LoginJWT: FC = (props) => {
  const mounted = useMounted();
  const { login } = useAuth() as any;

  return (
    <Formik
      initialValues={{
        address: '',
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        address: Yup.string().required(),
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting },
      ): Promise<void> => {
        try {
          await login(values.address);

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          console.error(err);
          if (mounted.current) {
            setStatus({
              success: false,
            });
            setErrors({
              submit: err.message,
            });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit} {...props}>
          <TextField
            autoFocus
            error={Boolean(touched.address && errors.address)}
            fullWidth
            helperText={touched.address && errors.address}
            label="Jobcoin Address"
            margin="normal"
            name="address"
            onBlur={handleBlur}
            onChange={handleChange}
            type="string"
            value={values.address}
            variant="outlined"
          />
          {errors.submit && (
            <Box>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Sign In
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default LoginJWT;
