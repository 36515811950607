import { Suspense, lazy } from "react";
import AuthGuard from "src/components/AuthGuard";
import GuestGuard from "src/components/GuestGuard";
import LoadingScreen from "src/components/LoadingScreen";
import { Navigate } from "react-router-dom";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Authentication pages

const Login = Loadable(lazy(() => import("src/pages/authentication/Login")));
const Dashboard = Loadable(lazy(() => import("src/pages/dashboard/Dashboard")));

// Error pages

const AuthorizationRequired = Loadable(
  lazy(() => import("src/pages/AuthorizationRequired"))
);
const NotFound = Loadable(lazy(() => import("src/pages/NotFound")));
const ServerError = Loadable(lazy(() => import("src/pages/ServerError")));

const routes = [
  {
    path: "/",
    element: <Navigate to="/login" />,
  },
  {
    path: "login",
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    ),
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <Dashboard />
      </AuthGuard>
    ),
  },
  {
    path: "*",
    element: <div />,
    children: [
      {
        path: "401",
        element: <AuthorizationRequired />,
      },
      {
        path: "404",
        element: <NotFound />,
      },
      {
        path: "500",
        element: <ServerError />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
